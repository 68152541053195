import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../Auth/Context";

export default function ProtectedRoute({ component: Component, ...rest }) {
    const { user, userData } = useAuth();
    const user_role = userData ? userData.user_role : null;

    return (
        <Route
            {...rest}
            render={props => {
                if (user && "admin" === user_role) {
                    return <Component {...props} />;
                }
                if (user && "user" === user_role) {
                    return <Redirect to="/error" />;
                }
                if (!user) {
                    return <Redirect to="/login" />;
                }
            }}
        ></Route>
    );
}
