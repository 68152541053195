import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Header, Button, Icon, Dropdown } from "semantic-ui-react";
import "../Home/Home.scss";

import { useAuth } from "./Context";
const logo = process.env.PUBLIC_URL + "/favicon.svg";

export function Login() {
    const [error, setError] = useState(null);
    const { signIn } = useAuth();
    const history = useHistory();

    async function handleSubmit(e) {
        e.preventDefault();
        const { error } = await signIn(
            {
                provider: "google",
            },
            {
                redirectTo: "https://psih.app/grafice",
            }
        );
        if (error) {
            setError(error);
            history.push("/error");
        } else {
            history.push("/grafice");
        }
    }

    return (
        <div className="out">
            <div className="logo">
                <img src={logo} alt="logo" width="200" />
            </div>
            <Header as="h2">
                <span>Teste</span> psihologice
            </Header>
            {error ? (
                <p>{error.message}</p>
            ) : (
                <Button onClick={handleSubmit}>
                    <Icon name="google" />
                    Login cu Google
                </Button>
            )}
        </div>
    );
}

export function Logout() {
    const { signOut } = useAuth();
    return <Dropdown.Item onClick={() => signOut()}>Logout</Dropdown.Item>;
}
