import { supabase } from "../Auth/Context";
// const mara = process.env.REACT_APP_MARA;

export const getProfile = async profile => {
    const user = profile;
    try {
        let { data, error, status } = await supabase.from("profiles").select(`id, username, first_name, last_name, email, user_role, avatar_url`).eq("id", user.id).single();
        if (status === 406) {
            const updates = {
                id: user.id,
                username: user.username,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                user_role: "user",
                avatar_url: user.avatar_url,
                updated_at: new Date(),
            };
            updateProfile(updates);
        }
        if (error && status !== 406) {
            throw error;
        }
        if (data) {
            return data;
        }
    } catch (error) {
        console.log(error);
    }
};

export const updateProfile = async ({ id, username, first_name, last_name, email, user_role, avatar_url }) => {
    try {
        const updates = {
            id: id,
            username,
            first_name,
            last_name,
            email,
            user_role,
            avatar_url,
            updated_at: new Date(),
        };
        let { error } = await supabase.from("profiles").upsert(updates, {
            returning: "minimal", // Don't return the value after inserting
        });

        if (error) {
            throw error;
        }
    } catch (error) {
        console.log(error);
    }
};
