import React from "react";
import { Header } from "semantic-ui-react";
import "../Home/Home.scss";

const logo = process.env.PUBLIC_URL + "/favicon.svg";

export function Error() {
    return (
        <div className="out">
            <div className="logo">
                <img src={logo} alt="logo" width="200" />
            </div>
            <Header as="h2">
                <span>Accesul nu este permis</span>
            </Header>
        </div>
    );
}
