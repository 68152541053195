import "./styles.scss";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/Auth/Context";
import { AuthContext } from "./components/Auth/Context";
import { supabase } from "./components/Auth/Context";

const rootElement = document.getElementById("root");
ReactDOM.render(
    <BrowserRouter>
        <AuthProvider client={supabase}>
            <AuthContext.Consumer>{value => <App session={value} />}</AuthContext.Consumer>
        </AuthProvider>
    </BrowserRouter>,
    rootElement
);
