import React, { useEffect, useState } from "react";
import { Logout } from "../Auth/Login";
import { Menu, Dropdown } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../Auth/Context";

const TopMenu = ({ children }) => {
    const [active, setActive] = useState("home");

    const menu = [
        {
            label: "Grafice",
            name: "grafice",
            path: "/grafice",
        },
        {
            label: "Admin",
            name: "admin",
            path: "/admin",
        },
    ];

    const renderMenu = menu =>
        menu.map((item, index) => {
            return (
                <Menu.Item as={NavLink} to={item.path} exact key={index} name={item.name} active={active === item.name} onClick={() => setActive(item.name)}>
                    {item.label}
                </Menu.Item>
            );
        });

    return (
        <div className="topmenu ui menu fixed mainpage">
            <div className="menuWrapper">
                <Menu pointing secondary>
                    <Dropdown.Item as={NavLink} exact to="/">
                        Home
                    </Dropdown.Item>
                    {renderMenu(menu)}
                    <MenuDropdown />
                </Menu>
                {children}
            </div>
        </div>
    );
};

export default TopMenu;

export function MenuDropdown() {
    const [avatar, setAvatar] = useState(null);
    const [name, setName] = useState(null);
    const [imgError, setImgError] = useState(false);
    const { user } = useAuth();

    useEffect(() => {
        const meta = user.user_metadata;
        const avatar = meta.avatar_url || "/favicon.svg";
        const name = meta.name.split(" ").slice(0, -1).join(" ") || "Setări";
        setName(name);
        setAvatar(avatar);

        // let ls = localStorage.getItem("supabase.auth.token");
        // if (null !== ls) {
        //     let obj = JSON.parse(ls);
        //     let avatar = obj.currentSession.user.user_metadata.avatar_url || "/favicon.svg";
        //     let full_name = obj.currentSession.user.user_metadata.full_name;
        //     let name = full_name.split(" ").slice(0, -1).join(" ") || "Oups";
        //     setAvatar(avatar);
        //     setName(name);
        // }
    }, [user]);

    return (
        <Dropdown
            pointing
            className="link item etcDropdown"
            trigger={
                <span>
                    {!imgError ? <img src={avatar} onError={() => setImgError(true)} className="userPicSmall" alt="Profile" /> : <div className="userPicSmall letter">{name[0]}</div>}
                    {name}
                </span>
            }
        >
            <Dropdown.Menu>
                <Dropdown.Item as={NavLink} to="/profile">
                    Profile
                </Dropdown.Item>
                <Logout />
            </Dropdown.Menu>
        </Dropdown>
    );
}
