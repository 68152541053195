import React, { Suspense, lazy, useState, useEffect } from "react";
import { Switch, Route, withRouter, useLocation } from "react-router-dom";
import ProtectedRoute from "./components/Auth/ProtectedRoute";
const TermsConditions = lazy(() => import("./components/Index/Terms"));
const PrivacyPolicy = lazy(() => import("./components/Index/Privacy"));
const CookiesPolicy = lazy(() => import("./components/Index/Cookies"));
const Index = lazy(() => import("./components/Index/Index"));
const EvalItem = lazy(() => import("./components/Index/EvalItem"));
const Evaluare = lazy(() => import("./components/Index/Evaluare"));
const Evaluari = lazy(() => import("./components/Index/Evaluari"));
const Test = lazy(() => import("./components/Index/Test"));
const Teste = lazy(() => import("./components/Index/Teste"));
const TestCat = lazy(() => import("./components/Index/TestCat"));
const EvalCat = lazy(() => import("./components/Index/EvalCat"));
const Psiholog = lazy(() => import("./components/Index/Despre/Psiholog"));
const Faq = lazy(() => import("./components/Index/Despre/Faq"));
const Pregatire = lazy(() => import("./components/Index/Despre/Pregatire"));
const AccessArrangement = lazy(() => import("./components/Index/Servicii/AccessArrangement"));
const EvaluareSimpla = lazy(() => import("./components/Index/Servicii/EvaluareSimpla"));
const EvaluareClinica = lazy(() => import("./components/Index/Servicii/EvaluareClinica"));
const Expertiza = lazy(() => import("./components/Index/Servicii/Expertiza"));
const Supervizare = lazy(() => import("./components/Index/Servicii/Supervizare"));
const Contact = lazy(() => import("./components/Index/Contact"));
const Home = lazy(() => import("./components/Home/Home"));
const Grafice = lazy(() => import("./components/Reports/Grafice/Grafice"));
const User = lazy(() => import("./components/User/User"));
const Admin = lazy(() => import("./components/Admin/Admin"));
import { Login } from "./components/Auth/Login";
import { Error } from "./components/Auth/Error";
import TopMenu from "./components/Home/Menu";
import { getProfile } from "./components/User/Functions";

const App = props => {
    const user = props.session.user;
    const [userData, setUserData] = useState(null);
    const { hash } = useLocation();
    useEffect(() => {
        if (hash && document.getElementById(hash.substr(1))) {
            document.getElementById(hash.substr(1)).scrollIntoView({ behavior: "smooth" });
        }
    }, [hash]);

    useEffect(() => {
        if (user) {
            const id = user.id;
            function addProfile(id) {
                const { name, picture, email } = user.user_metadata;
                const first_name = user.user_metadata.name.split(" ").slice(0, -1).join(" ");
                const last_name = user.user_metadata.name.split(" ").slice(-1).join(" ");
                const profile = {
                    id: id,
                    username: name,
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    user_role: "user",
                    avatar_url: picture,
                };
                const res = getProfile(profile);
                res.then(res => {
                    setUserData(res);
                });
            }
            addProfile(id);
        }
    }, [user]);

    return (
        <div className="App">
            {user && props.location.pathname === "/grafice" && <TopMenu children={null} />}
            <Suspense
                fallback={
                    <div className="loadingPlaceholder">
                        <span>Loading...</span>
                    </div>
                }
            >
                <Switch>
                    <Route exact path="/" component={props => <Index user={userData} {...props} />} />
                    <ProtectedRoute exact path="/home" component={props => <Home user={userData} {...props} />} />
                    <ProtectedRoute exact path="/grafice" component={props => <Grafice user={userData} {...props} />} />
                    <ProtectedRoute exact path="/profile" component={props => <User user={user.user_metadata} {...props} />} />
                    <ProtectedRoute exact path="/admin" component={props => <Admin user={user.user_metadata} {...props} />} />
                    <Route exact path="/teste/" component={props => <Teste user={userData} {...props} />} />
                    <Route exact path="/categorie/:slug" component={props => <TestCat user={userData} {...props} />} />
                    <Route exact path="/teste/:slug" component={props => <Test user={userData} {...props} />} />
                    <Route exact path="/evaluare/" component={props => <Evaluare user={userData} {...props} />} />
                    <Route exact path="/evaluari/" component={props => <Evaluari user={userData} {...props} />} />
                    <Route exact path="/evaluari/:slug" component={props => <EvalCat user={userData} {...props} />} />
                    <Route exact path="/evaluare/:slug" component={props => <EvalItem user={userData} {...props} />} />
                    <Route exact path="/login" component={props => <Login {...props} />} />
                    <Route exact path="/error" component={props => <Error {...props} />} />
                    <Route exact path="/politica-confidentialitate" component={props => <PrivacyPolicy user={userData} {...props} />} />
                    <Route exact path="/termeni-conditii" component={props => <TermsConditions user={userData} {...props} />} />
                    <Route exact path="/politica-cookies" component={props => <CookiesPolicy user={userData} {...props} />} />
                    <Route exact path="/evaluare-autocunoastere" component={props => <EvaluareSimpla user={userData} {...props} />} />
                    <Route exact path="/evaluare-psihologica-clinica" component={props => <EvaluareClinica user={userData} {...props} />} />
                    <Route exact path="/expertiza-psihologica-judiciara" component={props => <Expertiza user={userData} {...props} />} />
                    <Route exact path="/access-arrangement" component={props => <AccessArrangement user={userData} {...props} />} />
                    <Route exact path="/supervizare-psihologie-clinica" component={props => <Supervizare user={userData} {...props} />} />
                    <Route exact path="/despre" component={props => <Psiholog user={userData} {...props} />} />
                    <Route exact path="/intrebari-evaluare" component={props => <Faq user={userData} {...props} />} />
                    <Route exact path="/pregatire-evaluare-psihologica" component={props => <Pregatire user={userData} {...props} />} />
                    <Route exact path="/contact" component={props => <Contact user={userData} {...props} />} />
                    <Route component={props => <Index user={userData} {...props} />} />
                </Switch>
            </Suspense>
        </div>
    );
};
export default withRouter(App);
