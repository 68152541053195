import React, { useContext, useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";
import { getProfile } from "../User/Functions";

export const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState();

    useEffect(() => {
        const session = supabase.auth.session();

        setUser(session?.user ?? null);
        setLoading(false);

        let eventType = "";

        const { data: listener } = supabase.auth.onAuthStateChange(async (event, session) => {
            eventType = event;
            if (eventType !== "SIGNED_IN") {
                setUser(session?.user ?? null);
                setLoading(false);
            }
        });

        if (user && eventType !== "SIGNED_IN") {
            // console.log(listener);
            const id = user.id;
            function addProfile(id) {
                const { name, picture, email } = user.user_metadata;
                const profile = {
                    id: id,
                    username: name,
                    first_name: name.split(" ").slice(0, -1).join(" "),
                    last_name: name.split(" ").slice(-1).join(" "),
                    email: email,
                    user_role: "user",
                    avatar_url: picture,
                };
                const res = getProfile(profile);
                res.then(res => {
                    setUserData(res);
                });
            }
            addProfile(id);
        }

        return () => {
            undefined !== listener && listener.unsubscribe();
        };
    }, [user]);

    const value = {
        signUp: data => supabase.auth.signUp(data),
        signIn: data => supabase.auth.signIn(data),
        signOut: () => supabase.auth.signOut(),
        user,
        userData,
    };

    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}

export const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);
